.clientslides {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: #24344D;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 30%;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5vw; /* Adjust font size of the arrows */
    color: white; /* Arrow color */
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}

.left-arrow {
    left: 0;
    color: #A7bbcb;
    padding-left: 5%; /* Adjust padding for arrow position */
}

.right-arrow {
    right: 0;
    color: #A7bbcb;
    padding-right: 5%; /* Adjust padding for arrow position */
}

.slide {
    position: absolute; /* Position slides absolutely for overlapping */
    width: 100%;
    height: 100%;
    opacity: 0; /* Initially hide all slides */
    transition: opacity 0.7s ease; /* Apply fade transition */
}

.slide.active {
    opacity: 1; /* Make active slide visible */
}

.slide img {
    width: 80%;
    height: auto;
    max-width: 2000px;
}

.text {
    background-color: inherit; /* Inherit background color from parent slide */
    width: 100%;
    padding-left: 4%;
    padding-top: 3%;
}

.text h2 {
    font-size: 2vw;
    color: #A7bbcb;
}
.text p {
    color: #A7bbcb;
    margin: 0;
    font-size: 1vw;
}
