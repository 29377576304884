/* Custom styles for the dots */
.slick-dots {
    position: absolute;
    bottom: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.slick-dots li {
    margin: 0 5px;
}

.slick-dots li button {
    width: 0.75vw;
    height: 0.75vw;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.3;
    cursor: pointer;
}
.slick-dots li button:before {
    content: ' ';
}

.slick-dots li.slick-active button {
    opacity: 0.8;
}

.slick-slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)),
    rgba(0, 0, 0, 0.3); /* Combine black to transparent gradient with semi-transparent black overlay */
    z-index: 0; /* Make sure it's behind other content */
}

/* Default styles */
.overlay-heading {
    font-size: 2.3vw; /* Adjust the value as needed */
    position: absolute; /* Adjust position as needed */
    top: 30%; /* Adjust position as needed */
    left: 10%; /* Adjust position as needed */
    color: #fff;
    font-weight: bold;
}

.slide-image {
    width: 100vw; /* Set width to 100% of viewport width */
    height: auto; /* Maintain aspect ratio */
}

.overlay-text {
    font-size: 1.3vw; /* Adjust the value as needed */
    position: absolute; /* Adjust position as needed */
    max-width: 30%;
    top: 40%; /* Adjust position as needed */
    left: 10%; /* Adjust position as needed */
    color: #fff;
}

/* Add semi-transparent overlay */
.slick-slide {
    position: relative;
}

.slick-slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
}

/* Adjust styles for smaller screens */
@media screen and (max-width: 768px) {
    .overlay-heading {
        font-size: 2.5vw;
    }

    .overlay-text {
        font-size: 1.5vw;
        max-width: 40%;
    }

    .slick-dots li button {
        width: 0.25vw;
        height: 0.25vw;
    }
}

/* Adjust styles for even smaller screens */
@media screen and (max-width: 480px) {
    .overlay-heading {
        font-size: 3vw;
        top: 22%;
    }

    .overlay-text {
        font-size: 2vw;
        top: 32%;
        max-width: 40%;
    }

    .slick-dots li button {
        width: 0.25vw;
        height: 0.25vw;
    }
}
